<template>
	<page-container :page="page">
		<main-content
			:title="page.title || page.header"
			:color="page.color"
			:content="page.content"
			:heading-image="page.headingImages && page.headingImages[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>
